import './Landing.css'
import React from 'react'
import { Link } from 'react-router-dom'
import recipes from '../../data/recipes'

const Landing = props => (
  <>
    <div className='landing-container'>
      { Object.entries(recipes).map(([key, recipe]) => (
        <Link key={ key } className='recipe' to={ key } as="div">
          <div>{ recipe.short_name }</div>
        </Link>
      )) }
    </div>
  </>
)

export default Landing