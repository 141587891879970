import './Cocktail.css'
import React, { useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import recipes from '../../data/recipes'


const Ingredient = ({ ingredient }) => {
  const [showDetails, setShowDetails] = useState(false)
  const hasIngredients = ingredient.ingredients.length > 0
  return (
    <div className='ingredient-container'>
      <div
        className={ `ingredient ${hasIngredients ? '' : 'no-hover'} ${showDetails && hasIngredients ? 'light-text' : ''}` }
        onClick={ () => setShowDetails(!showDetails) }>
        { hasIngredients ? (showDetails ? '-' : '+') : `\u00A0\u00A0` } { ingredient.name } <span className='ingredient-amount'>- { ingredient.amount }</span>
      </div>
      <div className='ingredient-details-container' style={ { height: showDetails && hasIngredients ? '100%' : 0, overflow: 'hidden' } }>
        <ul className='ingredient-recipe'>
          { ingredient.ingredients.map(ir => (
            <li key={ ir.name + ir.amount } className='ingredient-recipe-item'>
              { ir.name } - { ir.amount }
            </li>
          )) }
        </ul>
        <ol className='ingredient-instructions'>
          { ingredient.instructions.map(instruction => (
            <li key={ instruction } className='ingredient-recipe-instruction'>
              { instruction }
            </li>
          )) }
        </ol>
      </div>
    </div >
  )
}

const Cocktail = props => {
  const { id } = useParams()

  if (!recipes[id]) {
    return <Navigate to="/" replace />
  }

  return (
    <div className='cocktail-container'>
      { recipes[id].warning && (
        <div className='cocktail-warning'>
          { recipes[id].warning }
        </div>
      ) }
      <h1 className='light-text'>{ recipes[id].name }</h1>
      <div className='sub-container'>
        <div className='description-container'>
          <div className='section-heading light-text'>Description</div>
          { recipes[id].description }
        </div>
        <div className='serve-container'>
          <div className='section-heading light-text'>Serve</div>
          { recipes[id].serve }
        </div>
        <div className='garnish-container'>
          <div className='section-heading light-text'>Garnish</div>
          { recipes[id].garnish }
        </div>
        <div className='glassware-container'>
          <div className='section-heading light-text'>Glassware</div>
          { recipes[id].glassware }
        </div>
        <div className='ingredients-container'>
          <div className='section-heading light-text'>Ingredients</div>
          { recipes[id].ingredients.map(ingredient => <Ingredient key={ ingredient.name } ingredient={ ingredient } />) }
        </div>
      </div>
    </div>
  )
}

export default Cocktail