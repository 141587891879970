import './App.css'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Menu from './components/Menu'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

const App = () => {
  const { pathname, search } = useLocation()
  ReactGA.initialize('G-F0K4R2SBN4')
  ReactGA.send({ hitType: "pageview", page: pathname + search })
  return (
    <div>
      <Menu />
      <Outlet />
    </div>
  )
}

export default App
