import './Menu.css'
import React from 'react'
import { Link } from 'react-router-dom'
import tayerLogo from '../../media/tayer-logo.png'
import elementaryLogo from '../../media/elementary-logo.png'

const Menu = props => (
  <Link className='menu-link' to='/'>
    <div className='logo-container'>
      <img height={ 20 } src={ tayerLogo } alt="tayer logo" />
      <div className='plus'>+</div>
      <img height={ 20 } src={ elementaryLogo } alt="elementary logo" />
      <div className='plus'>+</div>
      <div>kevin</div>
    </div>
  </Link>
)

export default Menu