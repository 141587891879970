import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import Pages from './pages'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom"
import reportWebVitals from './reportWebVitals'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Pages.Landing />,
      },
      {
        path: "/:id",
        element: <Pages.Cocktail />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ]
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={ router } />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
