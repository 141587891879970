const recipes = {
  'bkt-martini': {
    name: 'BKT Martini',
    short_name: 'BKT Martini',
    warning: '⚠ Read ingredients before trying! This is an adventure, BKT can be polarising as can this drink.',
    description: 'Bak Kut Teh (肉骨茶) or Pork Bone Tea is a Malaysian-Chinese soup dish. I had my first taste of this dish in 2022 when I visited Malaysia for the first time.',
    serve: 'Stir down in mixing glass with ice, pour into glass neat',
    garnish: 'Place a tiny Youtiao (油条) on rim, also known as Chinese fried dough',
    glassware: 'Nick & Nora',
    instructions: [],
    ingredients: [
      {
        name: 'Pork & Tofu Gin',
        amount: "30ml",
        instructions: [
          'Lightly salt pork rib',
          'Place pork rib, tofu, and gin into sous-vide bag',
          'Cook at 57°C for 3 hours',
          'Place in ice bath to stop cooking',
          'Strain gin using muslin cloth',
          'Place in freezer for one day; strain using muslin cloth again',
          'Place in freezer for an addtional day; strain using coffee filter',
        ],
        ingredients: [
          {
            amount: '700ml',
            name: 'Gin',
          },
          {
            amount: '400g',
            name: 'Pork Rib',
          },
          {
            amount: '100g',
            name: 'Puffed Tofu',
          },
        ],
      },
      {
        amount: "30ml",
        name: 'Nikka Coffey Gin',
        instructions: [],
        ingredients: [],
      },
      {
        name: 'Clarified BKT "Brine"',
        amount: "7.5ml",
        instructions: [
          'Combine BKT packet, soy sauce, dark soy sauce, oyster sauce, ground white pepper, saline and crushed garlic into bowl',
          'Pour boiling water over mixture, stir gently, then cover for 3 hours',
          'Add in gelatine using a slurry of warm water then place whole mixture into freezer overnight',
          'Remove from freezer and strain gelatin in muslin cloth over bowl'
        ],
        ingredients: [
          {
            amount: '1',
            name: 'Bak Kut Teh Packet',
          },
          {
            amount: '30ml',
            name: 'Soy Sauce',
          },
          {
            amount: '5ml',
            name: 'Dark Soy Sauce',
          },
          {
            amount: '15ml',
            name: 'Oyster Sauce',
          },
          {
            amount: '3g',
            name: 'White Pepper',
          },
          {
            amount: '10 cloves',
            name: 'Garlic',
          },
          {
            amount: '480g',
            name: 'Water',
          },
          {
            amount: '3.5g',
            name: 'Saline Solution',
          },
          {
            amount: '1 packet',
            name: 'Gelatin',
          },
        ],
      },
      {
        name: 'Shiitake Vermouth',
        amount: "15ml",
        instructions: [
          'Place mushrooms, and vermouth into sous-vide bag',
          'Cook at 52°C for 2 hours',
          'Place in ice bath to stop cooking',
          'Strain vermouth using muslin cloth',
          'Finally, strain using coffee filter',
        ],
        ingredients: [
          {
            amount: '750ml',
            name: 'Dry Vermouth',
          },
          {
            amount: '75g',
            name: 'Dried Shiitake Mushrooms',
          },
        ],
      },
    ],
  },
  'bbp-old-fashioned': {
    name: 'Bread & Butter Pudding Old Fashioned',
    short_name: 'BBP Old Fashioned',
    description: 'I had my first bread & butter pudding in December 2022 and was told by a local that she drinks an old fashioned with it. I found the flavour combination so amazing that I wanted to combine them into a single, drinkable experience.',
    serve: 'Stir down in mixing glass with ice, pour into glass with large ice cube',
    garnish: 'Small orange peel circle on top of large ice cube',
    glassware: 'Double rocks glass',
    instructions: [],
    ingredients: [
      {
        name: 'Custard-Washed Bourbon',
        amount: "30ml",
        instructions: [
          'Mix milk, cream, and yolks in a bowl',
          'Pour bourbon into "custard"',
          'Strain through coffee filter',
        ],
        ingredients: [
          {
            amount: '150g',
            name: 'Full-Fat Milk',
          },
          {
            amount: '50g',
            name: 'Double Cream',
          },
          {
            amount: '2',
            name: 'Egg Yolk',
          },
          {
            amount: '400ml',
            name: 'Bourbon',
          },
        ],
      },
      {
        name: 'Bread Pudding Bourbon',
        amount: "30ml",
        instructions: [
          'Butter and toast white bread',
          'Brown butter and pour into sultanas and currants',
          'Combine all ingredients into sous-vide bag',
          'Cook at 52°C for 2 hours',
          'Place in ice bath to stop cooking',
          'Strain bourbon using muslin cloth',
          'Place in freezer for one day; strain using muslin cloth again',
          'Place in freezer for an additional day; strain using coffee filter',
        ],
        ingredients: [
          {
            amount: '4 slices',
            name: 'Toasted White Bread',
          },
          {
            amount: '70g',
            name: 'Sultanas',
          },
          {
            amount: '70g',
            name: 'Currants',
          },
          {
            amount: '235g',
            name: 'Butter',
          },
          {
            amount: '700ml',
            name: 'Bourbon',
          },
        ],
      },
      {
        name: 'Woodford Bourbon',
        amount: "15ml",
        instructions: [],
        ingredients: [],
      },
      {
        name: 'Vanilla Bean Syrup',
        amount: "10ml",
        instructions: [
          'Scrape seeds out of vanilla pod',
          'On low heat, combine sugar, vanilla pod with seeds, and water',
          'Remove heat once sugar is fully dissolved',
          'Strain syrup using muslin cloth',
        ],
        ingredients: [
          {
            amount: '250g',
            name: 'Demerara sugar',
          },
          {
            amount: '150g',
            name: 'Water',
          },
          {
            amount: '1',
            name: 'Vanilla Pod',
          },
        ],
      },
      {
        name: 'Angostora bitters',
        amount: "2 dashes",
        instructions: [],
        ingredients: [],
      },
    ],
  },
  'pheny-benz': {
    name: 'Pheny-Benz',
    short_name: 'Pheny-Benz',
    description: 'I wanted to create a cocktail where all the ingredients share at least two of the same flavour molecules (2-Phenylethnaol & Benzyl Alcohol).',
    serve: 'Shake in tin with ice, pour into glass neat',
    garnish: 'Pandan leaf & mushroom',
    glassware: 'Nick & Nora',
    build_instructions: [],
    ingredients: [
      {
        name: 'Pandan Soju',
        amount: "3ml",
        instructions: [
          'Combine all ingredients into sous-vide bag',
          'Cook at 52°C for 2 hours',
          'Place in ice bath to stop cooking',
          'Strain soju using muslin cloth',
        ],
        ingredients: [
          {
            amount: '30g',
            name: 'Pandan'
          },
          {
            amount: '350ml',
            name: 'Soju',
          }
        ],
      },
      {
        name: 'Basil Soju',
        amount: "3ml",
        instructions: [
          'Combine all ingredients into sous-vide bag',
          'Cook at 52° for 2 hours',
          'Place in ice bath to stop cooking',
          'Strain soju using muslin cloth',
        ],
        ingredients: [
          {
            amount: '15g',
            name: 'Thai Basil'
          },
          {
            amount: '35g',
            name: 'Italian Basil'
          },
          {
            amount: '350ml',
            name: 'Soju',
          }
        ],
      },
      {
        name: 'Mushroom & Cacao Soju',
        amount: "3ml",
        instructions: [
          'Combine all ingredients into sous-vide bag',
          'Cook at 52°C for 2 hours',
          'Place in ice bath to stop cooking',
          'Strain soju using muslin cloth',
          'Finally, strain using coffee filter',
        ],
        ingredients: [
          {
            amount: '10g',
            name: 'Girolles Mushrooms'
          },
          {
            amount: '15g',
            name: 'Cacao Nibs'
          },
          {
            amount: '350ml',
            name: 'Soju',
          }
        ],
      },
      {
        name: 'Palm Sugar Syrup',
        amount: "25ml",
        instructions: [
          'On low heat, combine sugar and water',
          'Remove heat once sugar is fully dissolved',
        ],
        ingredients: [
          {
            amount: '250g',
            name: 'Palm Sugar',
          },
          {
            amount: '150g',
            name: 'Water',
          },
        ],
      },
      {
        name: 'Rice Shochu',
        amount: "60ml",
        instructions: [],
        ingredients: [],
      },
      {
        name: 'Sūpāsawā',
        amount: "30ml",
        instructions: [],
        ingredients: [],
      },
      {
        name: 'Chocolate bitters',
        amount: "2 dashes",
        instructions: [],
        ingredients: [],
      },
    ],
  }
}

export default recipes